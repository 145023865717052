import React from 'react';
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import { LicenseInfo } from '@mui/x-license';
import App from './App/App';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';

LicenseInfo.setLicenseKey(
	'0e5193eb5586157f79b46741c404b7f2Tz0xMDAwLEU9MzI5MDkwOTM4NzIwMSxTPXByZW1pdW0sTE09cGVycGV0dWFsLEtWPTI=',
);

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<App />
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

createRoot(container as Element).render(children);
