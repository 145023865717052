import React, { lazy } from 'react';
import {
	adminPanelMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import Split from '../pages/presentation/split/SplitPage';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	PROFILE: lazy(() => import('../pages/presentation/profile/ProfilePage')),
	CREDENTIALS: lazy(() => import('../pages/presentation/credentials/CredentialsPage')),
	FAQ: lazy(() => import('../pages/presentation/faq/FaqPage')),
	GUIDES: lazy(() => import('../pages/presentation/guides/GuidesPage')),
	STREAMLINKS: lazy(() => import('../pages/presentation/StreamLinksPage')),
	TM_APP_MANUAL: lazy(() => import('../pages/presentation/TmAppManualPage')),
	LINKVISITS: lazy(() => import('../pages/presentation/LinkVisitsPage')),
};
const ADMIN_PANEL = {
	HOME: lazy(() => import('../pages/presentation/admin/home')),
	BALANCES: lazy(() => import('../pages/presentation/admin/balances')),
	WORKER_STATS: lazy(() => import('../pages/presentation/admin/workerStats')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: dashboardPagesMenu.credentials.path,
		element: <LANDING.CREDENTIALS />,
	},
	{
		path: dashboardPagesMenu.myLinks.path,
		element: <LANDING.STREAMLINKS />,
	},
	{
		path: dashboardPagesMenu.tmAppManual.path,
		element: <LANDING.TM_APP_MANUAL />,
	},
	{
		path: dashboardPagesMenu.linkVisits.path,
		element: <LANDING.LINKVISITS />,
	},
	{
		path: demoPagesMenu.split.path,
		element: <Split />,
	},
	{
		path: dashboardPagesMenu.streamLinks.path,
		element: <LANDING.STREAMLINKS />,
	},
	{
		path: dashboardPagesMenu.profile.path,
		element: <LANDING.PROFILE />,
	},
	{
		path: dashboardPagesMenu.guides.path,
		element: <LANDING.GUIDES />,
	},
	{
		path: dashboardPagesMenu.faq.path,
		element: <LANDING.FAQ />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];

const admin = [
	{
		path: adminPanelMenu.balances.path,
		element: <ADMIN_PANEL.BALANCES />,
	},
	{
		path: adminPanelMenu.workerStats.path,
		element: <ADMIN_PANEL.WORKER_STATS />,
	},
	{
		path: adminPanelMenu.home.path,
		element: <ADMIN_PANEL.HOME />,
	},
];

const contents = [...presentation, ...admin];

export default contents;
