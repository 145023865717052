import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import UserImage from '../assets/img/wanna/wanna1.png';
import UserImageWebp from '../assets/img/wanna/wanna1.webp';

export interface IAuthContextProps {
	user: string;
	accessToken: string;
	setUser?(...args: unknown[]): unknown;
	setAccessToken?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('authUsername') || '');
	const [accessToken, setAccessToken] = useState<string>(
		localStorage.getItem('accessToken') || '',
	);
	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	useEffect(() => {
		localStorage.setItem('authUsername', user);
	}, [user]);

	useEffect(() => {
		localStorage.setItem('accessToken', accessToken);

		if (accessToken) {
			const userInfo = JSON.parse(atob(accessToken.split('.')[1]));
			setUserData({
				...userInfo,

				name: userInfo.email,
				src: UserImage,
				srcSet: UserImageWebp,
				surname: '',
			});

			setUser(userInfo.username || userInfo.email);
		}
	}, [accessToken]);

	useEffect(() => {
		if (!user) {
			setUserData({});
		}
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			accessToken,
			setAccessToken,
			userData,
		}),
		[user, userData, accessToken],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
