export const backendUrl = 'https://securemypass.com/client-api';
export const apiUrl = 'https://securemypass.com/api/v1';
// export const backendUrl = 'http://localhost:8001/client-api';

export function getAccessToken() {
	try {
		return localStorage.getItem('accessToken');
	} catch (e) {
		return null;
	}
}
