import React, { useState } from 'react';
import {
	Container,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Link,
	Box,
	IconButton,
	Alert,
} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import showNotification from '../../../components/extras/showNotification';
import { splitLink } from '../../../Api/links';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import useDarkMode from '../../../hooks/useDarkMode';

interface LinkData {
	section: string;
	row: string;
	seat: string;
	link: string;
}

const SplitLinks: React.FC = () => {
	const [link, setLink] = useState<string>('');
	const [links, setLinks] = useState<LinkData[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>('');

	const { setDarkModeStatus } = useDarkMode();
	setDarkModeStatus(false);

	const handleSubmit = async () => {
		if (link) {
			setError('');
			setLoading(true);
			let splitData: any = [];

			const result = await splitLink(link);

			if (result.success) {
				splitData = result.results;
			} else {
				setError(
					<span>
						Link not supported, please{' '}
						<a
							href='http://t.me/securemypass_support2'
							target='_blank'
							rel='noreferrer'>
							contact us on telegram
						</a>{' '}
						with the link so we can handle.
					</span>,
				);
			}

			setLinks([...splitData]);
			setLink('');
			setLoading(false);
		}
	};

	const handleCopy = (text: string) => {
		navigator.clipboard.writeText(text);
		showNotification('Success', 'link copied to clipboard', 'success', 1000);
	};

	const handleOpen = (l: string) => {
		window.open(l, '_blank');
	};

	return (
		<PageWrapper title='Split' isProtected={false}>
			<Container
				maxWidth='sm'
				style={{
					marginTop: '50px',
					display: 'flex',
					flexDirection: 'column',
					textAlign: 'center',
					alignItems: 'center',
					padding: '0 20px',
				}}>
				<Typography variant='h4' gutterBottom>
					Welcome to SecureMyPass!
				</Typography>
				<Typography variant='body1' gutterBottom>
					Use our free link splitter tool by pasting your link below and hitting submit.
					We accept not only SecureMyPass links but also those from other providers.
				</Typography>
				<Typography>
					Please verify the link before use. If you encounter any format issues or
					discrepancies, don't hesitate to reach out to us.
				</Typography>
				<Typography variant='body2' color='error' gutterBottom>
					Note: We are not responsible for faulty links. We process data as provided and
					cannot verify the integrity of the uploaded links.
				</Typography>
				{error && <Alert severity='error'>{error}</Alert>}
				<Box sx={{ marginBottom: '60px', width: '100%' }}>
					<TextField
						label='Enter your link OR links (Separated by a comma)'
						variant='outlined'
						fullWidth
						value={link}
						onChange={(e) => setLink(e.target.value)}
						style={{ marginBottom: '20px' }}
					/>
					<LoadingButton
						variant='contained'
						color='primary'
						onClick={handleSubmit}
						loading={loading}
						style={{ marginBottom: '20px' }}>
						{link?.includes(',') ? 'Merge' : 'Split'}
					</LoadingButton>
					{links.length > 0 && (
						<Box sx={{ maxHeight: 'calc(100vh - 380px)', overflow: 'auto' }}>
							<Typography variant='h6' style={{ marginBottom: '10px' }}>
								Results:
							</Typography>
							<Table size='small'>
								<TableHead>
									<TableRow>
										<TableCell align='center'>Section</TableCell>
										<TableCell align='center'>Row</TableCell>
										<TableCell align='center'>Seat</TableCell>
										<TableCell align='center'>Link</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{links.map((submittedLink, index) => (
										<TableRow key={submittedLink.link}>
											<TableCell align='center'>
												{submittedLink.section}
											</TableCell>
											<TableCell align='center'>
												{submittedLink.row}
											</TableCell>
											<TableCell align='center'>
												{submittedLink.seat}
											</TableCell>
											<TableCell align='center'>
												{/* {submittedLink.link} */}
												<IconButton
													onClick={() => handleCopy(submittedLink.link)}
													size='small'>
													<ContentCopyIcon fontSize='small' />
												</IconButton>
												<IconButton
													onClick={() => handleOpen(submittedLink.link)}
													size='small'>
													<OpenInNewIcon fontSize='small' />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<Box style={{ marginTop: '10px' }}>
								<Typography variant='body2' color='error'>
									Links will be deleted 3 days after event.
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
				<Box
					style={{
						position: 'fixed',
						bottom: 0,
						marginBottom: '20px',
						width: '100%',
						textAlign: 'center',
					}}>
					<Typography variant='body2'>
						Contact us on Telegram:{' '}
						<Link
							href='http://t.me/securemypass_support2'
							target='_blank'
							rel='noopener'>
							<TelegramIcon style={{ verticalAlign: 'middle' }} />{' '}
							@securemypass_support2
						</Link>
					</Typography>
				</Box>
			</Container>
		</PageWrapper>
	);
};

export default SplitLinks;
