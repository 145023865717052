import { apiUrl, backendUrl, getAccessToken } from './config';

export async function previewSplitLink(link) {
	try {
		const response = await fetch(`${backendUrl}/stream-links/split`, {
			method: 'POST',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
			body: JSON.stringify({
				link,
				preview: true,
			}),
		});

		const responseBody = await response.json();

		return responseBody;
	} catch (e) {
		return {
			success: false,
		};
	}
}

export async function apiGenerateLink(data) {
	const response = await fetch(`${apiUrl}/stream-links/`, {
		method: 'POST',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
		body: JSON.stringify({
			...data,
		}),
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function apiGetGeneratedLinks(data) {
	try {
		const response = await fetch(`${apiUrl}/stream-links/generated`, {
			method: 'POST',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
			body: JSON.stringify(data),
		});

		const responseBody = await response.json();

		return responseBody;
	} catch (e) {
		console.log(e);
		return {
			success: false,
		};
	}
}

export async function splitLink(link) {
	try {
		const response = await fetch(`${backendUrl}/stream-links/split`, {
			method: 'POST',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
			body: JSON.stringify({
				link,
			}),
		});

		const responseBody = await response.json();

		return responseBody;
	} catch (e) {
		return {
			success: false,
		};
	}
}

export async function modifyLinkAttributes(body) {
	try {
		const response = await fetch(`${apiUrl}/stream-links/attributes`, {
			method: 'PUT',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
			body: JSON.stringify(body),
		});

		const responseBody = await response.json();

		return responseBody;
	} catch (e) {
		return {
			success: false,
		};
	}
}

export async function getLinks(filters) {
	const response = await fetch(
		`${backendUrl}/stream-links/?${new URLSearchParams(filters).toString()}`,
		{
			method: 'GET',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
		},
	);

	const responseBody = await response.json();

	return responseBody;
}

export async function deleteLink(hash) {
	const response = await fetch(`${backendUrl}/stream-links/${hash}`, {
		method: 'DELETE',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function getEvents(filters) {
	const response = await fetch(
		`${backendUrl}/stream-links/events?${new URLSearchParams(filters).toString()}`,
		{
			method: 'GET',
			headers: {
				'content-type': 'Application/JSON',
				accesstoken: getAccessToken(),
			},
		},
	);

	const responseBody = await response.json();

	return responseBody;
}
export async function getLinkVisitLog(hash) {
	const response = await fetch(`${backendUrl}/stream-links/visit-log/${hash}`, {
		method: 'GET',
		headers: {
			'content-type': 'Application/JSON',
			accesstoken: getAccessToken(),
		},
	});

	const responseBody = await response.json();

	return responseBody;
}

export async function updateLinks({}) {
	// const response = await fetch(`${backendUrl}/stream-links/?pageSize=100`, {
	// 	method: 'GET',
	// 	headers: {
	// 		'content-type': 'Application/JSON',
	// 		accesstoken: getAccessToken(),
	// 	},
	// });

	// const responseBody = await response.json();

	// return responseBody;

	return true;
}
